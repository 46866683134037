<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="customize-title1" colspan="8">
            出国（境）任务审批表 <br />
            Approval Form For Overseas Works
          </td>
        </tr>
        <tr>
          <td class="text-center">填报日期<br />Application Time</td>
          <td class="text-center" colspan="3">
            <el-date-picker
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              v-model="ApplicationTime"
              type="date"
              placeholder="请选择 Please select"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            序号<br />
            No
          </td>
          <td class="text-center" colspan="3">{{ ApplicationNumber }}</td>
        </tr>
        <tr>
          <td class="text-center">出国任务 <br />Work Abroad</td>
          <td class="text-center" colspan="7">
            <el-input v-model="WorkAbroad" placeholder="请输入 Enter" />
          </td>
        </tr>
        <tr>
          <td class="text-center">
            出国（境）依据及 事由说明 <br />
            Work Basis and Description
          </td>
          <td class="text-center" colspan="7">
            <el-input
              v-model="WorkBasisandDescription"
              placeholder="请输入 Enter"
            />
          </td>
        </tr>

        <tr>
          <td class="text-center" rowspan="6">
            出国（境）团组 人员组成 <br />
            Team Members
          </td>
          <td class="text-center">
            Name in Chinese<br />
            中文姓名
          </td>
          <td class="text-center">
            Project Name<br />
            项目名称
          </td>
          <td class="text-center">
            Working Unit<br />
            用工单位
          </td>
          <td class="text-center">
            Nationality <br />
            国籍
          </td>
          <td class="text-center underline-text">
            Passport No<br />
            护照号
          </td>
          <td class="text-center">
            IQAMA/ID No.<br />
            暂住证号/入境号
          </td>
          <td class="text-center">SPONSOR</td>
        </tr>
        <tr v-for="(item, index) in CostAllocationDetails" :key="index">
          <td class="text-center">
            <el-input v-model="item.NameinChinese" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">
            <el-select
              v-model="item.ProjectID"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in projectList"
                :key="item.ProjectID"
                :label="item.ProjectName"
                :value="item.ProjectID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <el-select
              v-model="item.CompanyID"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in companyList"
                :key="item.ID"
                :label="item.CompanyCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <el-select
              v-model="item.Nationality"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in nationalityList"
                :key="item.ID"
                :label="item.CountryNameCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <el-input
              @input="changePassportCode(index)"
              @blur="changePassportCode(index)"
              v-model="item.PassportCode"
              placeholder="请输入 Enter"
            />
          </td>
          <td class="text-center">
            <el-input v-model="item.IQAMAID" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">
            <el-select
              v-model="item.SPONSOR"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in opinions1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="text-center">邀请单位<br />Invitation Unit</td>
          <td class="text-center" colspan="7">
            <el-input v-model="InvitationUnit" placeholder="请输入 Enter" />
          </td>
        </tr>
        <tr>
          <td class="text-center">
            出访国家 （地区） <br />Visit Countries (Area)
          </td>
          <td class="text-center" colspan="7">
            <el-input
              v-model="VisitCountriesorAreas"
              placeholder="请输入 Enter"
            />
          </td>
        </tr>
        <tr>
          <td class="text-center">
            出访时间及 停留天数 （含路程）<br />
            Visit Date and days
          </td>
          <td class="text-center" colspan="7">
            <div class="flex2">
              <el-date-picker
                v-model="evacuationDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                @change="changeEvacuationDate"
              >
              </el-date-picker>
              <span v-if="VisitDays" class="span">共{{ VisitDays }}天</span>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center">签证申办 相关信息<br />Issue Visa</td>
          <td class="text-center" colspan="7">
            <div class="flex">
              <div>
                <el-checkbox
                  v-model="GroupcompanytoIssueVisa"
                  @change="multipleExclusion1(1)"
                ></el-checkbox>
                <span>集团公司统一办理Group company</span>
              </div>
              <div>
                <el-checkbox
                  v-model="SNEIcompanytoIssueVisa"
                  @change="multipleExclusion1(2)"
                ></el-checkbox>
                <span>公司统一办理 SNEI company</span>
              </div>
              <div>
                <el-checkbox
                  v-model="OthercompanytoIssueVisa"
                  @change="multipleExclusion1(3)"
                ></el-checkbox>
                <span>其他单位统一办理 Other company</span>
              </div>
              <div>
                <el-checkbox
                  v-model="OthertoIssueVisa"
                  @change="multipleExclusion1(4)"
                ></el-checkbox>
                <span>其他:SNEMEC /Projects</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            费用来源 <br />
            Source of Expenses
          </td>
          <td class="text-center" colspan="7">
            <div class="flex">
              <div>
                <el-checkbox
                  v-model="GroupcompanytoSourceofExpenses"
                  @change="multipleExclusion2(1)"
                ></el-checkbox>
                <span>集团公司统一安排付费 Group company</span>
              </div>
              <div>
                <el-checkbox
                  v-model="SNEIcompanytoSourceofExpenses"
                  @change="multipleExclusion2(2)"
                ></el-checkbox>
                <span>公司付费SNEI company</span>
              </div>
              <div>
                <el-checkbox
                  v-model="OthercompanytoSourceofExpenses"
                  @change="multipleExclusion2(3)"
                ></el-checkbox>
                <span>不需公司付费 Other company</span>
              </div>
              <div>
                <el-checkbox
                  v-model="OthertoSourceofExpenses"
                  @change="multipleExclusion2(4)"
                ></el-checkbox>
                <span>其他:境外项目承担 SNEMEC /Projects</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center">国际旅行票务 需求说明 <br />Air Tickets</td>
          <td class="text-center" colspan="7">
            <div class="flex">
              <div>
                <el-checkbox
                  v-model="GroupcompanytoAirTickets"
                  @change="multipleExclusion3(1)"
                ></el-checkbox>
                <span>由集团公司统一安排 Group company</span>
              </div>
              <div>
                <el-checkbox
                  v-model="SNEIcompanytoAirTickets"
                  @change="multipleExclusion3(2)"
                ></el-checkbox>
                <span>由公司统一安排 SNEI company</span>
              </div>
              <div>
                <el-checkbox
                  v-model="OthercompanytoAirTickets"
                  @change="multipleExclusion3(3)"
                ></el-checkbox>
                <span>由其他单位统一安排 Other company</span>
              </div>
              <div>
                <el-checkbox
                  v-model="OthertoAirTickets"
                  @change="multipleExclusion3(4)"
                ></el-checkbox>
                <span>订制线路：SNEMEC /Projects</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            联系人 <br />
            Contact Person
          </td>
          <td class="text-center">
            <el-input v-model="ContactPerson" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">
            联络电话<br />
            Phone number
          </td>
          <td class="text-center" colspan="2">
            <el-input v-model="Phonenumber" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">E-mail</td>
          <td class="text-center" colspan="2">
            <el-input v-model="Email" placeholder="请输入 Enter" />
          </td>
        </tr>
        <tr>
          <td class="text-center" rowspan="2">
            所在单位（部门） 领导审核 <br />
            Unit (Department
          </td>
          <td class="text-center">
            经办人<br />
            lister
          </td>
          <td class="text-center" colspan="2">
            <div class="img-box">
              <img class="img" :src="Lister" />
            </div>
          </td>
          <td class="text-center">
            项目领导 <br />
            project leader
          </td>
          <td class="text-center" colspan="3">
            <div class="img-box">
              <img class="img" :src="ProjectLeader" />
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="3">
            中东公司领导 <br />
            Middle East Company leader
          </td>
          <td class="text-center" colspan="4">
            <div class="img-box">
              <img class="img" :src="MiddleEastCompanyLeader" />
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            党委组织部 （外事办公室） 审批 <br />
            Organization Department
          </td>
          <td class="text-center" colspan="7">
            <div class="img-box">
              <img class="img" :src="OrganizationDepartment" />
            </div>
          </td>
        </tr>

        <tr>
          <td class="text-center" rowspan="2">
            公司领导<br />
            审批Company Leaders
          </td>

          <td class="text-center" colspan="2">业务分管领导 Business leaders</td>
          <td class="text-center" colspan="3">
            境外业务分管领导 Overseas business leaders
          </td>
          <td class="text-center" colspan="3">
            外事分管领导 Foreign affairs leaders
          </td>
        </tr>
        <tr>
          <td class="text-title" colspan="2">
            <div class="img-box">
              <img class="img" :src="BusinessLeader" />
            </div>
          </td>
          <td class="text-center" colspan="3">
            <div class="img-box">
              <img class="img" :src="OverseasBusinessLeader" />
            </div>
          </td>
          <td class="text-center" colspan="2">
            <div class="img-box">
              <img class="img" :src="ForeignAffairsLeader" />
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center">备注<br />Remark</td>
          <td class="text-center" colspan="7">
            <el-input v-model="Remark" placeholder="请输入 Enter" />
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="8">
            The following content is only displayed online and is not in the
            print area 以下内容仅线上流转显示，非打印区域
          </td>
        </tr>
        <tr>
          <td class="text-center">
            Douments Upload<br />
            材料上传区域
          </td>
          <td class="text-center">
            <div class="shenpi shenpi-color" v-if="UploadingDocument1">
              <div @click="download(UploadingDocument1)">
                {{ UploadingDocument1 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(1)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment1"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 1<br />
                材料上传1<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center">
            <div class="shenpi shenpi-color" v-if="UploadingDocument2">
              <div @click="download(UploadingDocument2)">
                {{ UploadingDocument2 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(2)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment2"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 2<br />
                材料上传2<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center">
            <div class="shenpi shenpi-color" v-if="UploadingDocument3">
              <div @click="download(UploadingDocument3)">
                {{ UploadingDocument3 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(3)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment3"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 3<br />
                材料上传3<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument4">
              <div @click="download(UploadingDocument4)">
                {{ UploadingDocument4 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(4)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment4"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 4<br />
                材料上传4<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument5">
              <div @click="download(UploadingDocument5)">
                {{ UploadingDocument5 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(5)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment5"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 5<br />
                材料上传5<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { countDates, datesString } from "@/utils/date";
import { getProjectAll, getCompanyAll, uploadAttachment } from "@/api/user";
import {
  getGeneralBasic,
  getUsersCode,
  addOverseasWorksApplication,
  editOverseasWorksApplicationsItem,
} from "@/api/table";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    purchaseOrderFrom(id, data) {
      const item = data.find((item) => {
        return item.KeyID === id;
      });
      return item ? item.AbbreviationName : null;
    },
    purchaseOrderFrom2(id, data) {
      const item = data.find((item) => {
        return item.KeyID === id;
      });
      return item ? item.CRNO : null;
    },
    stringFormater(data) {
      let name1 = process.env.VUE_APP_SERVER + "upload/";
      let index1 = data.indexOf(name1);
      data = data.slice(index1 + name1.length, data.length - 1);
      const index2 = data.indexOf("/");
      const index3 = data.lastIndexOf(".");
      data = data.slice(index2 + 1, index3);
      return data;
    },
    englishLetterFrom(id, data) {
      let name = "";
      if (!id && id !== 0) {
        return;
      }
      name = data[id];
      return name;
    },
  },
  data() {
    return {
      TableDate: null,
      ApplicationNumber: null,
      projectList: [],
      subProjectList: [],
      companyList: [],
      positionList: [],
      CostAllocationDetails: [],
      positionCategoryList: [],
      purchaseOrdersList: [],
      nationalityList: [],
      ID: null,
      KeyID: null,
      ApplicationTime: null,
      SerialNumber: null,
      ApplicationNumber: null,
      WorkAbroad: null,
      WorkBasisandDescription: null,
      InvitationUnit: null,
      VisitCountriesorAreas: null,
      VisitStartDate: null,
      VisitEndDate: null,
      VisitDays: null,
      VisitDateanddays: null,
      GroupcompanytoIssueVisa: false,
      SNEIcompanytoIssueVisa: false,
      OthercompanytoIssueVisa: false,
      OthertoIssueVisa: false,
      GroupcompanytoSourceofExpenses: false,
      SNEIcompanytoSourceofExpenses: false,
      OthercompanytoSourceofExpenses: false,
      OthertoSourceofExpenses: false,
      GroupcompanytoAirTickets: false,
      SNEIcompanytoAirTickets: false,
      OthercompanytoAirTickets: false,
      OthertoAirTickets: false,
      ContactPerson: null,
      Phonenumber: null,
      Email: null,
      Lister: null,
      ListerRemark: null,
      ListerDate: null,
      ProjectLeader: null,
      ProjectLeaderRemark: null,
      ProjectLeaderDate: null,
      MiddleEastCompanyLeader: null,
      MiddleEastCompanyLeaderRemark: null,
      MiddleEastCompanyLeaderDate: null,
      OrganizationDepartment: null,
      OrganizationDepartmentRemark: null,
      OrganizationDepartmentDate: null,
      BusinessLeader: null,
      BusinessLeaderRemark: null,
      BusinessLeaderDate: null,
      OverseasBusinessLeader: null,
      OverseasBusinessLeaderRemark: null,
      OverseasBusinessLeaderDate: null,
      ForeignAffairsLeader: null,
      ForeignAffairsRemark: null,
      ForeignAffairsDate: null,
      UploadingDocument1: null,
      UploadingDocument2: null,
      UploadingDocument3: null,
      UploadingDocument4: null,
      UploadingDocument5: null,
      Remark: null,
      EditState: 1,
      evacuationDate: [],
      costAllocationItem: {
        ID: null,
        KeyID: null,
        MainKeyID: null,
        EmployeeID: null,
        EmployeeCode: null,
        NameinChinese: null,
        NameinEnglish: null,
        ProjectID: null,
        ProjectName: null,
        SubProjectID: null,
        SubProjectName: null,
        CompanyID: null,
        WorkingUnit: null,
        Nationality: null,
        NationalityName: null,
        PassportCode: null,
        IQAMAID: null,
        SPONSOR: null,
      },
      opinions1: [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ],
    };
  },
  methods: {
    addCostAllocation(EditState) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        ApplicationTime: this.ApplicationTime,
        SerialNumber: this.SerialNumber,
        ApplicationNumber: this.ApplicationNumber,
        WorkAbroad: this.WorkAbroad,
        WorkBasisandDescription: this.WorkBasisandDescription,
        InvitationUnit: this.InvitationUnit,
        VisitCountriesorAreas: this.VisitCountriesorAreas,
        VisitStartDate: this.VisitStartDate,
        VisitEndDate: this.VisitEndDate,
        VisitDays: this.VisitDays,
        VisitDateanddays: this.VisitDateanddays,
        GroupcompanytoIssueVisa: this.GroupcompanytoIssueVisa,
        SNEIcompanytoIssueVisa: this.SNEIcompanytoIssueVisa,
        OthercompanytoIssueVisa: this.OthercompanytoIssueVisa,
        OthertoIssueVisa: this.OthertoIssueVisa,
        GroupcompanytoSourceofExpenses: this.GroupcompanytoSourceofExpenses,
        SNEIcompanytoSourceofExpenses: this.SNEIcompanytoSourceofExpenses,
        OthercompanytoSourceofExpenses: this.OthercompanytoSourceofExpenses,
        OthertoSourceofExpenses: this.OthertoSourceofExpenses,
        GroupcompanytoAirTickets: this.GroupcompanytoAirTickets,
        SNEIcompanytoAirTickets: this.SNEIcompanytoAirTickets,
        OthercompanytoAirTickets: this.OthercompanytoAirTickets,
        OthertoAirTickets: this.OthertoAirTickets,
        ContactPerson: this.ContactPerson,
        Phonenumber: this.Phonenumber,
        Email: this.Email,
        Lister: this.Lister,
        ListerRemark: this.ListerRemark,
        ListerDate: this.ListerDate,
        ProjectLeader: this.ProjectLeader,
        ProjectLeaderRemark: this.ProjectLeaderRemark,
        ProjectLeaderDate: this.ProjectLeaderDate,
        MiddleEastCompanyLeader: this.MiddleEastCompanyLeader,
        MiddleEastCompanyLeaderRemark: this.MiddleEastCompanyLeaderRemark,
        MiddleEastCompanyLeaderDate: this.MiddleEastCompanyLeaderDate,
        OrganizationDepartment: this.OrganizationDepartment,
        OrganizationDepartmentRemark: this.OrganizationDepartmentRemark,
        OrganizationDepartmentDate: this.OrganizationDepartmentDate,
        BusinessLeader: this.BusinessLeader,
        BusinessLeaderRemark: this.BusinessLeaderRemark,
        BusinessLeaderDate: this.BusinessLeaderDate,
        OverseasBusinessLeader: this.OverseasBusinessLeader,
        OverseasBusinessLeaderRemark: this.OverseasBusinessLeaderRemark,
        OverseasBusinessLeaderDate: this.OverseasBusinessLeaderDate,
        ForeignAffairsLeader: this.ForeignAffairsLeader,
        ForeignAffairsRemark: this.ForeignAffairsRemark,
        ForeignAffairsDate: this.ForeignAffairsDate,
        UploadingDocument1: this.UploadingDocument1,
        UploadingDocument2: this.UploadingDocument2,
        UploadingDocument3: this.UploadingDocument3,
        UploadingDocument4: this.UploadingDocument4,
        UploadingDocument5: this.UploadingDocument5,
        Remark: this.Remark,
        EditState,
        Details: [],
      };
      this.CostAllocationDetails.forEach((item) => {
        if (item.NameinChinese && item.PassportCode) {
          data.Details.push(JSON.parse(JSON.stringify(item)));
        }
      });

      if (!this.ID) {
        addOverseasWorksApplication(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      } else {
        editOverseasWorksApplicationsItem(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      }
    },
    geteditData(params) {
      console.log("params", params);
      this.ApplicationNumber = params.ApplicationNumber;
      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.ApplicationTime = params.ApplicationTime;
      this.SerialNumber = params.SerialNumber;
      this.ApplicationNumber = params.ApplicationNumber;
      this.WorkAbroad = params.WorkAbroad;
      this.WorkBasisandDescription = params.WorkBasisandDescription;
      this.InvitationUnit = params.InvitationUnit;
      this.VisitCountriesorAreas = params.VisitCountriesorAreas;
      this.VisitStartDate = params.VisitStartDate;
      this.VisitEndDate = params.VisitEndDate;

      if (this.VisitStartDate && this.VisitEndDate) {
        this.evacuationDate.push(this.VisitStartDate);
        this.evacuationDate.push(this.VisitEndDate);
      }
      this.VisitDays = params.VisitDays;
      this.VisitDateanddays = params.VisitDateanddays;
      this.GroupcompanytoIssueVisa = params.GroupcompanytoIssueVisa;
      this.SNEIcompanytoIssueVisa = params.SNEIcompanytoIssueVisa;
      this.OthercompanytoIssueVisa = params.OthercompanytoIssueVisa;
      this.OthertoIssueVisa = params.OthertoIssueVisa;
      this.GroupcompanytoSourceofExpenses =
        params.GroupcompanytoSourceofExpenses;
      this.SNEIcompanytoSourceofExpenses = params.SNEIcompanytoSourceofExpenses;
      this.OthercompanytoSourceofExpenses =
        params.OthercompanytoSourceofExpenses;
      this.OthertoSourceofExpenses = params.OthertoSourceofExpenses;
      this.GroupcompanytoAirTickets = params.GroupcompanytoAirTickets;
      this.SNEIcompanytoAirTickets = params.SNEIcompanytoAirTickets;
      this.OthercompanytoAirTickets = params.OthercompanytoAirTickets;
      this.OthertoAirTickets = params.OthertoAirTickets;
      this.ContactPerson = params.ContactPerson;
      this.Phonenumber = params.Phonenumber;
      this.Email = params.Email;
      this.Lister = params.Lister;
      this.ListerRemark = params.ListerRemark;
      this.ListerDate = params.ListerDate;
      this.ProjectLeader = params.ProjectLeader;
      this.ProjectLeaderRemark = params.ProjectLeaderRemark;
      this.ProjectLeaderDate = params.ProjectLeaderDate;
      this.MiddleEastCompanyLeader = params.MiddleEastCompanyLeader;
      this.MiddleEastCompanyLeaderRemark = params.MiddleEastCompanyLeaderRemark;
      this.MiddleEastCompanyLeaderDate = params.MiddleEastCompanyLeaderDate;
      this.OrganizationDepartment = params.OrganizationDepartment;
      this.OrganizationDepartmentRemark = params.OrganizationDepartmentRemark;
      this.OrganizationDepartmentDate = params.OrganizationDepartmentDate;
      this.BusinessLeader = params.BusinessLeader;
      this.BusinessLeaderRemark = params.BusinessLeaderRemark;
      this.BusinessLeaderDate = params.BusinessLeaderDate;
      this.OverseasBusinessLeader = params.OverseasBusinessLeader;
      this.OverseasBusinessLeaderRemark = params.OverseasBusinessLeaderRemark;
      this.OverseasBusinessLeaderDate = params.OverseasBusinessLeaderDate;
      this.ForeignAffairsLeader = params.ForeignAffairsLeader;
      this.ForeignAffairsRemark = params.ForeignAffairsRemark;
      this.UploadingDocument1 = params.UploadingDocument1;
      this.UploadingDocument2 = params.UploadingDocument2;
      this.UploadingDocument3 = params.UploadingDocument3;
      this.UploadingDocument4 = params.UploadingDocument4;
      this.UploadingDocument5 = params.UploadingDocument5;
      this.Remark = params.Remark;
      params.Details.forEach((_, index, Array) => {
        if (params.Details[index]) {
          this.CostAllocationDetails[index] = JSON.parse(
            JSON.stringify(params.Details[index])
          );
        }
      });
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
    },
    clearData() {
      this.ApplicationNumber = null;
      this.ID = null;
      this.KeyID = null;
      this.ApplicationTime = null;
      this.SerialNumber = null;
      this.ApplicationNumber = null;
      this.WorkAbroad = null;
      this.WorkBasisandDescription = null;
      this.InvitationUnit = null;
      this.VisitCountriesorAreas = null;
      this.VisitStartDate = null;
      this.VisitEndDate = null;
      this.evacuationDate = [];

      this.VisitDays = null;
      this.VisitDateanddays = null;
      this.GroupcompanytoIssueVisa = null;
      this.SNEIcompanytoIssueVisa = null;
      this.OthercompanytoIssueVisa = null;
      this.OthertoIssueVisa = null;
      this.GroupcompanytoSourceofExpenses = null;
      this.SNEIcompanytoSourceofExpenses = null;
      this.OthercompanytoSourceofExpenses = null;
      this.OthertoSourceofExpenses = null;
      this.GroupcompanytoAirTickets = null;
      this.SNEIcompanytoAirTickets = null;
      this.OthercompanytoAirTickets = null;
      this.OthertoAirTickets = null;
      this.ContactPerson = null;
      this.Phonenumber = null;
      this.Email = null;
      this.Lister = null;
      this.ListerRemark = null;
      this.ListerDate = null;
      this.ProjectLeader = null;
      this.ProjectLeaderRemark = null;
      this.ProjectLeaderDate = null;
      this.MiddleEastCompanyLeader = null;
      this.MiddleEastCompanyLeaderRemark = null;
      this.MiddleEastCompanyLeaderDate = null;
      this.OrganizationDepartment = null;
      this.OrganizationDepartmentRemark = null;
      this.OrganizationDepartmentDate = null;
      this.BusinessLeader = null;
      this.BusinessLeaderRemark = null;
      this.BusinessLeaderDate = null;
      this.OverseasBusinessLeader = null;
      this.OverseasBusinessLeaderRemark = null;
      this.OverseasBusinessLeaderDate = null;
      this.ForeignAffairsLeader = null;
      this.ForeignAffairsRemark = null;
      this.UploadingDocument1 = null;
      this.UploadingDocument2 = null;
      this.UploadingDocument3 = null;
      this.UploadingDocument4 = null;
      this.UploadingDocument5 = null;
      this.Remark = null;
      this.CostAllocationDetails = [];
      for (let i = 0; i < 5; i++) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }
    },

    //通过护照读取数据
    async changePassportCode(index) {
      const value = this.CostAllocationDetails[index].PassportCode;
      if (!value) {
        return;
      }
      let userInfo = null;
      await getUsersCode({ code: value, codeType: 1 }).then((res) => {
        if (res.status === 200 && res.response) {
          userInfo = res.response;
        }
      });
      if (userInfo) {
        console.log("userInfo", userInfo);
        this.CostAllocationDetails[index].NameinChinese = userInfo.ChineseName;
        this.CostAllocationDetails[index].ProjectID = userInfo.ProjectID;
        this.CostAllocationDetails[index].CompanyID = userInfo.CompanyID;
        this.CostAllocationDetails[index].Nationality = userInfo.CountryID;
        this.CostAllocationDetails[index].IQAMAID = userInfo.IQAMAID;
        this.CostAllocationDetails[index].SPONSOR = userInfo.Sponsor;
      }
    },
    //通过日期计算天数
    changeEvacuationDate() {
      if (!this.evacuationDate || this.evacuationDate.length === 0) {
        this.VisitStartDate = null;
        this.VisitEndDate = null;
        this.VisitDays = null;
        this.VisitDateanddays = null;
        return;
      }
      this.VisitStartDate = this.evacuationDate[0];
      this.VisitEndDate = this.evacuationDate[1];
      this.VisitDays = countDates(this.VisitStartDate, this.VisitEndDate);

      this.VisitDateanddays = datesString(
        this.VisitStartDate,
        this.VisitEndDate,
        this.VisitDays
      );
    },

    //多选款互斥1
    multipleExclusion1(type) {
      switch (type) {
        case 1:
          if (this.GroupcompanytoIssueVisa) {
            this.SNEIcompanytoIssueVisa = false;
            this.OthercompanytoIssueVisa = false;
            this.OthertoIssueVisa = false;
          }
          break;
        case 2:
          if (this.SNEIcompanytoIssueVisa) {
            this.GroupcompanytoIssueVisa = false;
            this.OthercompanytoIssueVisa = false;
            this.OthertoIssueVisa = false;
          }
          break;
        case 3:
          if (this.OthercompanytoIssueVisa) {
            this.GroupcompanytoIssueVisa = false;
            this.SNEIcompanytoIssueVisa = false;
            this.OthertoIssueVisa = false;
          }
          break;
        case 4:
          if (this.OthertoIssueVisa) {
            this.GroupcompanytoIssueVisa = false;
            this.SNEIcompanytoIssueVisa = false;
            this.OthercompanytoIssueVisa = false;
          }
          break;
      }
    },
    //多选款互斥2
    multipleExclusion2(type) {
      switch (type) {
        case 1:
          if (this.GroupcompanytoSourceofExpenses) {
            this.SNEIcompanytoSourceofExpenses = false;
            this.OthercompanytoSourceofExpenses = false;
            this.OthertoSourceofExpenses = false;
          }
          break;
        case 2:
          if (this.SNEIcompanytoSourceofExpenses) {
            this.GroupcompanytoSourceofExpenses = false;
            this.OthercompanytoSourceofExpenses = false;
            this.OthertoSourceofExpenses = false;
          }
          break;
        case 3:
          if (this.OthercompanytoSourceofExpenses) {
            this.GroupcompanytoSourceofExpenses = false;
            this.SNEIcompanytoSourceofExpenses = false;
            this.OthertoSourceofExpenses = false;
          }
          break;
        case 4:
          if (this.OthertoSourceofExpenses) {
            this.GroupcompanytoSourceofExpenses = false;
            this.SNEIcompanytoSourceofExpenses = false;
            this.OthercompanytoSourceofExpenses = false;
          }
          break;
      }
    },
    //多选款互斥3
    multipleExclusion3(type) {
      switch (type) {
        case 1:
          if (this.GroupcompanytoAirTickets) {
            this.SNEIcompanytoAirTickets = false;
            this.OthercompanytoAirTickets = false;
            this.OthertoAirTickets = false;
          }
          break;
        case 2:
          if (this.SNEIcompanytoAirTickets) {
            this.GroupcompanytoAirTickets = false;
            this.OthercompanytoAirTickets = false;
            this.OthertoAirTickets = false;
          }
          break;
        case 3:
          if (this.OthercompanytoAirTickets) {
            this.GroupcompanytoAirTickets = false;
            this.SNEIcompanytoAirTickets = false;
            this.OthertoAirTickets = false;
          }
          break;
        case 4:
          if (this.OthertoAirTickets) {
            this.GroupcompanytoAirTickets = false;
            this.SNEIcompanytoAirTickets = false;
            this.OthercompanytoAirTickets = false;
          }
          break;
      }
    },
    deleteItem(index) {
      switch (index) {
        case 1:
          this.UploadingDocument1 = null;
          break;
        case 2:
          this.UploadingDocument2 = null;
          break;
        case 3:
          this.UploadingDocument3 = null;
          break;
        case 4:
          this.UploadingDocument4 = null;
          break;
        case 5:
          this.UploadingDocument5 = null;
          break;
      }
    },
    uploadAttachment1(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument1 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment2(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument2 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment3(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument3 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment4(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument4 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment5(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument5 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    download(data) {
      console.log("data");
      location.href = data;
    },
  },
  created() {
    for (let i = 0; i < 5; i++) {
      const costAllocationItem = JSON.parse(
        JSON.stringify(this.costAllocationItem)
      );
      this.CostAllocationDetails.push(costAllocationItem);
    }
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    //国籍
    getGeneralBasic().then((res) => {
      if (res.status === 200) {
        this.nationalityList = res.response.Countries;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .tr-active {
    background-color: #ecf5ff;
  }
  .text-size {
    font-size: 16px;
    color: #000;
    text-align: center;
    max-width: 200px;
    .el-checkbox {
      margin: 10px 0;
    }
    span {
      margin-right: 20px;
    }
    div {
      margin: 10px 0;
    }
    .input-box {
      display: inline-block;
      width: 100px;
      border-bottom: 1px solid #000;
      margin: 10px 0;
    }
  }
  .text-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      width: 50%;
    }
    .sign-box {
      display: flex;
      align-items: center;
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
      .flex {
        display: flex;
        font-size: 13px;
        color: #000;
        align-items: center;
        justify-content: space-around;
        margin: 10px 0;

        .el-checkbox {
          font-size: 18px;
          color: #000;
          margin-right: 10px;
        }
        .input-box {
          width: 100px;
          border-bottom: 1px solid #000;
          margin: 0 10px;
        }
      }
      .flex2 {
        display: flex;
        font-size: 13px;
        color: #000;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
        .span {
          flex-shrink: 0;
          margin-left: 30px;
        }
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 13px;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
.shenpi {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: column;
  cursor: pointer;
  .shenpi-tips {
    color: #315efb;
    margin-left: 0;
    margin-top: 3px;
  }
}
.shenpi-color {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: row;
  color: #315efb;
  cursor: pointer;
  span {
    color: red;
    margin-left: 10px;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.card-upload >>> .el-upload-list {
  display: none;
}
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}

.tr-active >>> .el-input__inner {
  background-color: #ecf5ff;
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.el-date-editor {
  width: 100%;
}
.el-checkbox >>> .el-checkbox__inner {
  background-color: #fff !important;
  border-color: #000 !important;
}
.el-checkbox >>> .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border-color: #000;
}
.el-date-editor {
  max-width: 500px;
}
.sign {
  max-width: 200px;
}
.img-box {
  min-height: 50px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 0;
  display: flex;
  align-items: center;
}
.img-box > img {
  max-width: 200px;
  margin-left: 0;
}
</style>